<!--
Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<template>
  <div class="container">
    <b-row>
      <b-col>
        <b-card header="Privacy policy manager">
          <b-row>
            <b-col>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Revision</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="policy in policies" :key="policy.id">
                    <td>Policy # {{ policy.id }}</td>
                    <td v-if="policy.status === 'Active'">
                      <strong>Active </strong>
                      <b-icon-check-circle variant="success" />
                    </td>
                    <td v-if="policy.status === 'Pending'">
                      <strong>Pending </strong>
                      <b-icon-dash-circle variant="warning" />
                    </td>
                    <td v-if="policy.status === 'Archived'">
                      <strong>Archived </strong>
                      <b-icon-archive variant="info" />
                    </td>
                    <td>
                      <b-button
                        variant="outline-primary"
                        size="sm"
                        class="m-1"
                        @click.prevent="populatePolicyForm(policy)"
                      >
                        <div v-if="policy.status === 'Pending'">
                          <b-icon-pencil /> Edit
                        </div>
                        <div v-else><b-icon-box-arrow-up-right /> View</div>
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-row>
                <b-col>
                  <b-button
                    @click="showNewPolicyForm"
                    size="sm"
                    class="m-1"
                    variant="primary"
                  >
                    <b-icon-plus font-scale="1.3"></b-icon-plus>New Policy
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col>
        <b-card header="EULA manager">
          <b-row>
            <b-col>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Revision</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="eula in eulaList" :key="eula.id">
                    <td>EULA # {{ eula.id }}</td>
                    <td v-if="eula.status === 'Active'">
                      <strong>Active </strong>
                      <b-icon-check-circle variant="success" />
                    </td>
                    <td v-if="eula.status === 'Pending'">
                      <strong>Pending </strong>
                      <b-icon-dash-circle variant="warning" />
                    </td>
                    <td v-if="eula.status === 'Archived'">
                      <strong>Archived </strong>
                      <b-icon-archive variant="info" />
                    </td>
                    <td>
                      <b-button
                        variant="outline-primary"
                        size="sm"
                        class="m-1"
                        @click.prevent="populateEulaForm(eula)"
                      >
                        <div v-if="eula.status === 'Pending'">
                          <b-icon-pencil /> Edit
                        </div>
                        <div v-else><b-icon-box-arrow-up-right /> View</div>
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-row>
                <b-col>
                  <b-button
                    @click="showNewEulaForm"
                    size="sm"
                    class="m-1"
                    variant="primary"
                  >
                    <b-icon-plus font-scale="1.3"></b-icon-plus>New EULA
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="modal-edit-privacy-policy"
      size="lg"
      @ok="savePolicy"
      ok-title="Save Policy"
      :title="
        policyEditModel.id
          ? 'View Policy ID#' + policyEditModel.id
          : 'New Policy'
      "
      :ok-disabled="!isPolicyEditable"
    >
      <form @submit.prevent="savePolicy">
        <b-row>
          <b-col>
            <b-form-checkbox
              v-if="isPolicyEditable"
              v-model="policyEditModel.status"
              :value="'Active'"
              :unchecked-value="false"
              :disabled="!isPolicyEditable"
            >
              Activate
            </b-form-checkbox>
            <div v-else>
              <td v-if="policyEditModel.status === 'Active'">
                <strong>Active </strong>
                <b-icon-check-circle variant="success" />
              </td>
              <td v-else-if="policyEditModel.status === 'Archived'">
                <strong>Archived </strong>
                <b-icon-archive variant="info" />
              </td>
            </div>
            <br />

            <b-tabs>
              <b-tab
                :title="language.name"
                v-for="language in languages"
                :key="language.id"
              >
                <b-form-group :label="'Privacy Policy (' + language.name + ')'">
                  <b-form-textarea
                    v-model="
                      policyEditModel.privacyPolicyForms[language.id]
                        .privacyPolicy
                    "
                    rows="5"
                    :disabled="!isPolicyEditable"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group
                  :label="'Consent Questions (' + language.name + ')'"
                >
                  <b-form-textarea
                    v-model="
                      policyEditModel.privacyPolicyForms[language.id]
                        .consentQuestions
                    "
                    rows="5"
                    :disabled="!isPolicyEditable"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group
                  :label="
                    'Privacy Agreement - therapist (' + language.name + ')'
                  "
                >
                  <b-form-textarea
                    v-model="
                      policyEditModel.privacyPolicyForms[language.id]
                        .agreeConsentTherapist
                    "
                    rows="5"
                    :disabled="!isPolicyEditable"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group
                  :label="'Privacy Agreement - patient (' + language.name + ')'"
                >
                  <b-form-textarea
                    v-model="
                      policyEditModel.privacyPolicyForms[language.id]
                        .agreeConsentPatient
                    "
                    rows="5"
                    :disabled="!isPolicyEditable"
                  ></b-form-textarea>
                </b-form-group>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </form>
      <div class="form-group">
        <div v-if="errorMessage" class="alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-edit-eula"
      size="lg"
      @ok="saveEula"
      ok-title="Save EULA"
      :title="
        eulaEditModel.id ? 'View EULA ID#' + eulaEditModel.id : 'New EULA'
      "
      :ok-disabled="!isEulaEditable"
    >
      <form @submit.prevent="saveEula">
        <b-row>
          <b-col>
            <b-form-checkbox
              v-if="isEulaEditable"
              v-model="eulaEditModel.status"
              :value="'Active'"
              :unchecked-value="false"
              :disabled="!isEulaEditable"
            >
              Activate
            </b-form-checkbox>
            <div v-else>
              <td v-if="eulaEditModel.status === 'Active'">
                <strong>Active </strong>
                <b-icon-check-circle variant="success" />
              </td>
              <td v-else-if="eulaEditModel.status === 'Archived'">
                <strong>Archived </strong>
                <b-icon-archive variant="info" />
              </td>
            </div>
            <br />

            <b-tabs>
              <b-tab
                :title="language.name"
                v-for="language in languages"
                :key="language.id"
              >
                <b-form-group :label="'EULA (' + language.name + ')'">
                  <b-form-textarea
                    v-model="eulaEditModel.eulaForms[language.id].eula"
                    rows="5"
                    :disabled="!isEulaEditable"
                  ></b-form-textarea>
                </b-form-group>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </form>
      <div class="form-group">
        <div v-if="errorMessage" class="alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  name: "DpoDashboard",
  data() {
    return {
      languages: [
        { id: 0, code: "en", name: "English" },
        { id: 1, code: "fr", name: "French" },
        { id: 2, code: "de", name: "German" },
        { id: 3, code: "it", name: "Italian" },
        { id: 4, code: "es", name: "Spanish" },
      ],
      policyEditModel: {
        privacyPolicyForms: [
          {
            privacyPolicy: "",
            consentQuestions: "",
            agreeConsentTherapist: "",
            agreeConsentPatient: "",
          },
          {
            privacyPolicy: "",
            consentQuestions: "",
            agreeConsentTherapist: "",
            agreeConsentPatient: "",
          },
          {
            privacyPolicy: "",
            consentQuestions: "",
            agreeConsentTherapist: "",
            agreeConsentPatient: "",
          },
          {
            privacyPolicy: "",
            consentQuestions: "",
            agreeConsentTherapist: "",
            agreeConsentPatient: "",
          },
          {
            privacyPolicy: "",
            consentQuestions: "",
            agreeConsentTherapist: "",
            agreeConsentPatient: "",
          },
        ],
        status: "Pending",
      },
      isPolicyEditable: true,
      policies: [],
      eulaEditModel: {
        eulaForms: [
          {
            eula: "",
          },
          {
            eula: "",
          },
          {
            eula: "",
          },
          {
            eula: "",
          },
          {
            eula: "",
          },
        ],
        status: "Pending",
      },
      isEulaEditable: true,
      eulaList: [],
      errorMessage: ''
    };
  },
  async created() {
    this.refreshData();
  },
  methods: {
    async refreshData() {
      this.refreshPrivacyPolicies();
      this.refreshEulaList();
    },
    async refreshPrivacyPolicies() {
      this.loading = true;
      this.policies = await ApiService.getPolicies();
      this.loading = false;
    },
    async refreshEulaList() {
      this.loading = true;
      this.eulaList = await ApiService.getEulaList();
      this.loading = false;
    },
    async savePolicy(event) {
      event.preventDefault();
      for (var i = 0; i < this.languages.length; i++) {
        this.policyEditModel.privacyPolicyForms[i].language =
          this.languages[i].code;
      }
      if (this.policyEditModel.id) {
        await ApiService.updatePolicy(this.policyEditModel.id, this.policyEditModel).then(
                            () => { 
                                this.resetForm(); 
                                // Hide the modal manually
                                this.$nextTick(() => {
                                    this.$bvModal.hide("modal-edit-privacy-policy");
                                });
                            },
                            (error) => {
                                    this.errorMessage =
                                    (error.response && error.response.data)
                                    || error.message
                                    || error.toString();
                                });
      } else {
        this.policyEditModel.id = 0;
        await ApiService.addPolicy(this.policyEditModel).then(
                            () => { 
                                this.resetForm();
                                // Hide the modal manually
                                this.$nextTick(() => {
                                    this.$bvModal.hide("modal-edit-privacy-policy");
                                });
                            },
                            (error) => {
                                    this.errorMessage =
                                    (error.response && error.response.data)
                                    || error.message
                                    || error.toString();
                                });
      }
      await this.refreshPrivacyPolicies();
    },
    populatePolicyForm(policy) {
      var tempPrivacyPolicies = [];
      for (let language of this.languages) {
        var found = false;
        for (let policyForm of policy.privacyPolicyForms) {
          if (language.code == policyForm.language) {
            tempPrivacyPolicies.push(policyForm);
            found = true;
          }
        }

        if (!found) {
          tempPrivacyPolicies.push({
            privacyPolicy: "",
            consentQuestions: "",
            agreeConsentTherapist: "",
            agreeConsentPatient: "",
          });
        }
      }
      this.policyEditModel = Object.assign({}, policy);
      this.policyEditModel.privacyPolicyForms = tempPrivacyPolicies;
      this.isPolicyEditable = this.policyEditModel.status === "Pending";
      this.$bvModal.show("modal-edit-privacy-policy");
      this.errorMessage = '';
    },
    showNewPolicyForm() {
      this.resetForm();
      this.$bvModal.show("modal-edit-privacy-policy");
      this.errorMessage = '';
    },
    resetForm() {
      for (var i = 0; i < this.languages.length; i++) {
        this.policyEditModel.privacyPolicyForms[i].agreeConsentTherapist = "";
        this.policyEditModel.privacyPolicyForms[i].agreeConsentPatient = "";
        this.policyEditModel.privacyPolicyForms[i].consentQuestions = "";
        this.policyEditModel.privacyPolicyForms[i].privacyPolicy = "";
      }
      this.policyEditModel.status = "Pending";
      this.isPolicyEditable = true;
      this.policyEditModel.id = null;
      this.errorMessage = '';
    },

    async saveEula(event) {
      event.preventDefault()
      for (var i = 0; i < this.languages.length; i++) {
        this.eulaEditModel.eulaForms[i].language = this.languages[i].code;
      }
      if (this.eulaEditModel.id) {
        await ApiService.updateEula(this.eulaEditModel.id, this.eulaEditModel).then(
                            () => { 
                                this.resetEulaForm();
                                // Hide the modal manually
                                this.$nextTick(() => {
                                  this.$bvModal.hide("modal-edit-eula");
                                });
                            },
                            (error) => {
                                    this.errorMessage =
                                    (error.response && error.response.data)
                                    || error.message
                                    || error.toString();
                                });
      } else {
        this.eulaEditModel.id = 0;
        await ApiService.addEula(this.eulaEditModel).then(
                            () => { 
                                this.resetEulaForm();
                                // Hide the modal manually
                                this.$nextTick(() => {
                                  this.$bvModal.hide("modal-edit-eula");
                                });
                            },
                            (error) => {
                                    this.errorMessage =
                                    (error.response && error.response.data)
                                    || error.message
                                    || error.toString();
                                });
      }
      await this.refreshEulaList();
    },
    populateEulaForm(eula) {
      var tempEulaList = [];
      for (let language of this.languages) {
        var found = false;
        for (let eulaForm of eula.eulaForms) {
          if (language.code == eulaForm.language) {
            tempEulaList.push(eulaForm);
            found = true;
          }
        }

        if (!found) {
          tempEulaList.push({
            eula: "",
          });
        }
      }
      this.eulaEditModel = Object.assign({}, eula);
      this.eulaEditModel.eulaForms = tempEulaList;
      this.isEulaEditable = this.eulaEditModel.status === "Pending";
      this.$bvModal.show("modal-edit-eula");
      this.errorMessage = '';
    },
    showNewEulaForm() {
      this.resetEulaForm();
      this.$bvModal.show("modal-edit-eula");
      this.errorMessage = '';
    },
    resetEulaForm() {
      for (var i = 0; i < this.languages.length; i++) {
        this.eulaEditModel.eulaForms[i].eula = "";
      }
      this.eulaEditModel.status = "Pending";
      this.isEulaEditable = true;
      this.eulaEditModel.id = null;
      this.errorMessage = '';
    },
  },
};
</script>

<style scoped></style>
